import './scss/style.scss';
import 'bootstrap';

import { WOW } from 'wowjs';
import { Swiper, Autoplay, Pagination } from 'swiper/js/swiper.esm.js';

Swiper.use([Autoplay, Pagination]);

/*Se inicializa el plugin para las animaciones*/
var wow = new WOW({
    live: false,
    scrollContainer: null
});

/*Leer parametros de la url*/
function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

/*Todos los "load" cargan los bloques recurrentes*/
$('header').load("header.html");
$('footer').load("footer.html");
$('.block-monlux-activity').load("block-monlux-activity.html", function() {
    wow.init();
});

/*Script para el slider del home*/
if ($.contains(document.body, document.getElementById('slider-main'))) {

    var mySwiper = new Swiper('#slider-main', {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });
}

$('.carousel-works').carousel({
    interval: false
});

$('.block-modal-works').on('click', '.btn-secondary', function (event) {
    event.preventDefault();
    var item = $(this).data('item');
    $(item).carousel('prev');
});

$('.block-modal-works').on('click', '.btn-primary', function (event) {
    event.preventDefault();
    var item = $(this).data('item');
    $(item).carousel('next');
});

$('.block-modal-works').on('show.bs.modal', function (event) {
    var to = parseInt(event.relatedTarget.attributes['data-to'].value);
    $(this).carousel(to);
});

$('.btn-scrolldown').on('click', function(event) {
    event.preventDefault();
    $('body,html').animate({ scrollTop: $('#block-first-section').offset().top - 150 }, 1000, 'swing');
});

$('.btn-change-list').on('click', function(event) {
    event.preventDefault();

    var not_items = $($(this).data('item')).fadeIn();

    $('.block-list-works > div > div > div').not(not_items).hide();

    $('.btn-change-list').removeClass('active');
    $(this).addClass('active');
});

/*Activar seccion de obras por parametro*/
var p = getParameterByName('p');

if (!!p) {
    $('.btn-change-list').removeClass('active');
    $(`#btn-change-list-${p}`).addClass('active');
    $(`#btn-change-list-${p}`).trigger("click");
}

/*Se agregan las animaciones para toda la pagina que no cargan de menera recurrente*/
wow.init();